import React from "react"
import ReactMarkdown from "react-markdown"
// import { useStaticQuery, graphql } from "gatsby"


function CookiesText(props) {
  
    return (
     <section className="bot-cookies-text">
     <div className="bot-policy_in">
         <h3 className="bot-cookies-title">{props.page.title}</h3>
         <div className="bot-cookies-text">
         <ReactMarkdown source={props.page.description} linkTarget="_blank"/>
         </div>
     </div>
 </section>
    )
}

export default CookiesText